import React, {useState, useEffect} from 'react';
import AES from 'crypto-js/aes';
import queryString from 'query-string';
import fetch from 'node-fetch';
import ScaleLoader from 'react-spinners/ScaleLoader';
import getPageClassName from './helpers/getPageClassName';
import getErrorMessage from './helpers/getErrorMessage';
import dictionary from './dictionary.json';
import './reset-password.scss';

const resetAPI = 'https://api.c33.io/user-merchant/reset-password';

const ResetPassword = () => {
  let lang = queryString.parse(window.location.search).lang || 'en';
  if (lang !== 'en' && lang !== 'de') lang = 'en';
  const token = queryString.parse(window.location.search).token;
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [successState, setSuccessState] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const submitResetPassword = () => {
    if (isLoading !== false) return true;
    if (newPassword.length < 1 || confirmPassword.length < 1) {
      setSuccessState('errorEmpty');
    } else if (newPassword !== confirmPassword) {
      setSuccessState('errorNotMatch');
    } else if (!token) {
      setSuccessState('errorNoToken');
    } else {
      const newPair = AES.encrypt(newPassword, '6s2wnAPIuxHnRQctQYR3V_JMJrNZfWjm');
      setIsLoading(true);
      fetch(resetAPI, {
        method: 'post',
        body: JSON.stringify({
          token,
          password: newPair.toString(),
        }),
        headers: { 'Content-Type': 'application/json' },
      })
      .then(res => res.json())
      .then(json => {
        setIsLoading(false);
        if (json.success) return setSuccessState('success');
        return setSuccessState('errorUnknown');
      });
    }
  };
  
  let submitText = dictionary[lang].submit;
  if (isLoading) {
    submitText = (
      <ScaleLoader
      color="#fff"
      width={3}
        height={15}
      />
    );
  }

  useEffect(() => {
    document.title = dictionary[lang].documentTitle;
  });

  const formState = getPageClassName(successState);
  const errorMessage = getErrorMessage(successState);
  return (
    <div className={formState}>
      <h1 className="reset-password__logo">Connect33</h1>
      <div className="reset-password__form-wrapper">
        <h3 className="reset-password__title">{dictionary[lang].title}</h3>
        {errorMessage}
        <ul className="reset-password__form-list">
          <li className="reset-password__form-list-item">
            <label 
              htmlFor="reset-password__password"
              className="reset-password__form-item-label"
            >
                {dictionary[lang].password}
            </label>
            <input
              id="reset-password__password"
              type="password"
              name="password" 
              className="reset-password__form-item-text"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value.trim())}
            />
          </li>
          <li className="reset-password__form-list-item">
            <label
              htmlFor="reset-password__confirm-password"
              className="reset-password__form-item-label"
            >
              {dictionary[lang].confirm}
            </label>
            <input
              id="reset-password__confirm-password"
              type="password" 
              name="confirm_password" 
              className="reset-password__form-item-text"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value.trim())}
            />
          </li>
        </ul>
        <button onClick={() => submitResetPassword()} className="reset-password__submit">{submitText}</button>
      </div>
      <div className="reset-password__success-wrapper">
        <p className="reset-password__success-wrapper-p">
          {dictionary[lang].successMessage}
        </p>
        <p className="reset-password__success-wrapper-p">
          {dictionary[lang].successInstruction}
        </p>
      </div>
    </div>
  )
};

export default ResetPassword;
