export const getPageClassName = (successState) => {
  if (successState === 'success') {
    return 'reset-password reset-password--success';
  }
  if ([
    'errorEmpty',
    'errorNotMatch',
    'errorNoToken',
    'errorUnknown',
  ].indexOf(successState) > -1) {
    return 'reset-password reset-password--error';
  }
  return 'reset-password';
};
export default getPageClassName;
