import React from 'react';
import dictionary from '../dictionary.json';
import queryString from 'query-string';

let lang = queryString.parse(window.location.search).lang || 'en';
if (lang !== 'en' && lang !== 'de') lang = 'en';

export const getErrorMessage = (successState) => {
  if (successState === 'errorEmpty') {
    return (
      <p className="reset-password__error-msg">
        {dictionary[lang].errorRequiredFields}
      </p>
    );
  }
  if (successState === 'errorNotMatch') {
    return (
      <p className="reset-password__error-msg">
        {dictionary[lang].errorPasswordNotMatch}
      </p>
    );
  }
  if (successState === 'errorNoToken') {
    return (
      <p className="reset-password__error-msg">
        {dictionary[lang].errorInvalidLink}
      </p>
    );
  }
  if (successState === 'errorUnknown') {
    return (
      <p className="reset-password__error-msg">
        {dictionary[lang].errorUnknown}
      </p>
    );
  }
  return null;
};
export default getErrorMessage;
